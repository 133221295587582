import React from 'react'
import {Typography} from '@material-ui/core'

const RefundPolicy = () => {
  return (
    <Typography style={{whiteSpace: 'pre-line'}}>
      {
        'Refund policy for all nScripts:\n' +
        '\n' +
        'TLDR: Don\'t expect a refund for my scripts unless there is a serious issue that I can\'t fix.\n' +
        '\n' +
        'The only case where a refund is guaranteed is when there is a major script issue that I am unable to fix for a period of time. Though this is unlikely to ever happen, it could occur (ex. on vacation).\n' +
        '\n' +
        'Refunds generally are not given at all - they won\'t be given because you are unable to bot for awhile, you got banned, or other similar circumstances.\n' +
        '\n' +
        'Don\'t purchase one of my scripts expecting to get a refund if you aren\'t satisfied; please try out the trial (all of my scripts offer trials).\n' +
        'If you are hesitant and aren\'t sure if you want to buy and have already used the trial, the safe choice is to not purchase the script (as opposed to expecting a refund).\n' +
        '\n' +
        'Do some research on the script before you purchase it. Don\'t purchase a script and expect to get a refund because it doesn\'t support something that you thought it would. Check the forums/trial to see if the feature is listed anywhere, or you can always ask me.\n' +
        '\n' +
        'Refunds are occasionally given in very rare circumstances at my discretion, though this is very unlikely. You can try to message me your situation but please don\'t take it personally if the only response I give is "no". Expect that to be the response if you do ask.\n' +
        '\n' +
        'If you just recently purchased the script and have hardly used it, I do sometimes allow you to switch your auth to another one of my scripts. This requires that you message me first about it, and if I approve it, you buy the other script auth first before the refund.\n' +
        '\n' +
        'These apply to everyone - please don\'t message me asking to not follow my policy or to make an exception, unless instructed above.'
      }
    </Typography>
  )
}

export default RefundPolicy