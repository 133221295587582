const convertRuntime = runtimeSeconds => {
  const types = {
    year: 31536000,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1
  }
  const parts = []
  for (const [key, value] of Object.entries(types)) {
    const div = Math.floor( runtimeSeconds / value)
    if (div == 0) {
      continue
    }
    parts.push(`${div} ${key}${div == 1 ? '' : 's'}`)
    if (parts.length >= 2) {
      break
    }
    runtimeSeconds %= value
  }
  const last = parts.pop()
  if (parts.length == 0) {
    return last
  }
  return `${parts.join(', ')} and ${last}`
}

export { convertRuntime }