import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {Grid, TextField} from '@material-ui/core'
import SignatureImage from './signature-image'

const UserSignatureSelector = (props) => {
  const [name, setName] = useState('')
  return (
    <Grid container spacing={3} style={{textAlign: 'center'}}>
      <Grid item xs={12}>
        <TextField fullWidth label="TRiBot Username" variant="outlined" value={name} onChange={e => setName(e.target.value)} />
      </Grid>
      <Grid item xs={12} md={12}>
        <SignatureImage name={name} script={props.script} />
      </Grid>
      <Grid item xs={12} lg={12}>
        <SignatureImage name="All Users" script={props.script} />
      </Grid>
    </Grid>
  )
}

UserSignatureSelector.propTypes = {
  script: PropTypes.object.isRequired
}

export default UserSignatureSelector