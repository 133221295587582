import axios from 'axios'

axios.defaults.baseURL = 'https://nscripting.com/api'

const HighscoresClient = {
  getHighscoresData: (type, searchText, order, ascending, page, pageSize) =>
    axios.get(`/highscores/${type}`, { params: { page, pageSize, order, ascending, searchText }}),
  getTotals: (type) => axios.get(`/highscores/${type}/sum`)
}

export default HighscoresClient