import React from 'react'
import {Container} from '@material-ui/core'
import StatisticsTable from '../components/statistics-table'

const Statistics = () => {
  return (
    <Container>
      <StatisticsTable />
    </Container>
  )
}

export default Statistics