export const nmz = {
  name: 'nNightmare Zone',
  description: 'Automate the nightmare zone minigame for afk combat experience',
  route: 'nmz',
  signatures: true,
  forums: 'https://community.tribot.org/topic/80147-nnightmare-zone-abc2-customization-progression/',
  store: 'https://tribot.org/store/products/73-nNightmare-Zone',
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime',
      defaultSort: 'desc'
    },
    attack: {
      title: 'Attack',
      type: 'number'
    },
    strength: {
      title: 'Strength',
      type: 'number'
    },
    defence: {
      title: 'Defence',
      type: 'number'
    },
    hitpoints: {
      title: 'Hitpoints',
      type: 'number'
    },
    ranged: {
      title: 'Ranged',
      type: 'number'
    },
    magic: {
      title: 'Magic',
      type: 'number'
    }
  }
}

export const blastfurnace = {
  name: 'nBlast Furnace',
  route: 'blastfurnace',
  description: 'Automate the blast furnace minigame for experience and profit',
  apiRoute: 'blastfurnace',
  forums: 'https://community.tribot.org/topic/77771-300k-1mhr-nblast-furnace-low-requirements-fresh-account-preparing-ge-restocking-all-efficiency-items-and-methods-supported/',
  store: 'https://tribot.org/store/products/84-nBlast-Furnace',
  signatures: true,
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime'
    },
    profit: {
      title: 'Profit',
      type: 'number'
    },
    exp: {
      title: 'Exp',
      type: 'number',
      defaultSort: 'desc'
    },
    bars: {
      title: 'Bars',
      type: 'number'
    },
  }
}

export const crabkiller = {
  name: 'nCrab Killer',
  route: 'crabkiller',
  description: 'Automate killing every type of crab for experience',
  apiRoute: 'crabkiller',
  forums: 'https://community.tribot.org/topic/77853-ncrab-killer-abc2-all-sand-crab-locations-ammonite-crabs-rock-crabs-progressive-training-82-hour-proggy',
  store: 'https://tribot.org/store/products/81-nCrab-Killer',
  signatures: true,
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime',
      defaultSort: 'desc'
    },
    attack: {
      title: 'Attack',
      type: 'number'
    },
    strength: {
      title: 'Strength',
      type: 'number'
    },
    defense: {
      title: 'Defence',
      type: 'number'
    },
    hitpoints: {
      title: 'Hitpoints',
      type: 'number'
    },
    ranged: {
      title: 'Ranged',
      type: 'number'
    },
    magic: {
      title: 'Magic',
      type: 'number'
    }
  }
}

export const mercher = {
  name: 'nMercher',
  route: 'mercher',
  description: 'Automate flipping items through the grand exchange for profit',
  apiRoute: 'mercher',
  forums: 'https://community.tribot.org/topic/79219-100k-1mhr-nmercher-intelligent-grand-exchange-flipper-customization-abc2-200b-total-profit/',
  store: 'https://tribot.org/store/products/74-nMercher',
  signatures: true,
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime'
    },
    profit: {
      title: 'Profit',
      type: 'number',
      defaultSort: 'desc'
    }
  }
}

export const smither = {
  name: 'nSmither',
  route: 'smither',
  description: 'Automate smithing/smelting items for experience and profit',
  apiRoute: 'smither',
  signatures: true,
  forums: 'https://community.tribot.org/topic/78618-nsmither-smithing-smelting-cannonballs-restocking-muling-dwarf-cannon-superheating-progressive-support-customization-150b-total-profit15b-total-exp/',
  store: 'https://tribot.org/store/products/68-nSmither',
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime'
    },
    exp: {
      title: 'Exp',
      type: 'number',
      defaultSort: 'desc'
    },
    profit: {
      title: 'Profit',
      type: 'number'
    }
  }
}

export const herblore = {
  name: 'nHerblore',
  route: 'herblore',
  description: 'Automate creating potions and other herblore tasks for experience',
  apiRoute: 'herblore',
  signatures: true,
  forums: 'https://community.tribot.org/topic/78789-nherblore-all-potions-all-unf-potions-all-herbs-all-tars-ge-support-crushing-progressive-mode-customization-abc2-1b-exp-gained/',
  store: 'https://tribot.org/store/products/77-nHerblore',
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime'
    },
    exp: {
      title: 'Exp',
      type: 'number',
      defaultSort: 'desc'
    }
  }
}

export const cwars = {
  name: 'nCastle Wars',
  route: 'cwars',
  description: 'Automate playing castle wars to accumulate tickets',
  apiRoute: 'cwars',
  forums: 'https://tribot.org/forums/topic/72598-free-ncastle-wars/',
  store: 'https://tribot.org/user/community-scripts?page=1&q=Naton&id=76',
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime'
    },
    tickets: {
      title: 'Tickets',
      type: 'number',
      defaultSort: 'desc'
    }
  }
}

export const magic = {
  name: 'nMagic',
  route: 'magic',
  description: 'Automate casting spells for experience and profit',
  apiRoute: 'magic',
  forums: 'https://community.tribot.org/topic/79632-nmagic-regular-spells-lunar-spells-grand-exchange-restocking-progressive-system-muling-customization-abc2',
  store: 'https://tribot.org/store/products/75-nMagic',
  signatures: true,
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime'
    },
    exp: {
      title: 'Exp',
      type: 'number',
      defaultSort: 'desc'
    }
  }
}

export const blastmine = {
  name: 'nBlast Mine',
  route: 'blastmine',
  description: 'Automate the blast mine activity for experience and profit',
  apiRoute: 'blastmine',
  forums: 'https://community.tribot.org/topic/78970-300k-700khr-nblast-mine-sulphur-mining-custom-blast-mining-paths-customization/',
  store: 'https://tribot.org/store/products/83-nBlast-Mine',
  signatures: true,
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime'
    },
    profit: {
      title: 'Profit',
      type: 'number'
    },
    exp: {
      title: 'Exp',
      type: 'number',
      defaultSort: 'desc'
    }
  }
}

export const firemaker = {
  name: 'nFiremaker',
  route: 'firemaker',
  description: 'Automate creating fires for experience',
  apiRoute: 'firemaker',
  forums: 'https://community.tribot.org/',
  store: 'https://tribot.org/user/community-scripts?page=1&q=Naton&id=161',
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime'
    },
    exp: {
      title: 'Exp',
      type: 'number',
      defaultSort: 'desc'
    }
  }
}

export const crafter = {
  name: 'nCrafter',
  route: 'crafter',
  description: 'Automate crafting items for experience and profit',
  apiRoute: 'crafter',
  signatures: true,
  forums: 'https://community.tribot.org/topic/80945-ncrafter-abc2-progression-supports-most-items-restocking-muling/',
  store: 'https://tribot.org/store/products/80-nCrafter',
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime'
    },
    exp: {
      title: 'Exp',
      type: 'number',
      defaultSort: 'desc'
    },
    profit: {
      title: 'Profit',
      type: 'number'
    }
  }
}

export const fletcher = {
  name: 'nFletcher',
  route: 'fletcher',
  description: 'Automate fletching items for experience and profit',
  apiRoute: 'fletcher',
  signatures: true,
  forums: 'https://community.tribot.org/topic/80943-nfletcher-abc2-progression-customization-supports-most-items-restocking-muling-extremely-reliable-stable-100b-total-profit100b-total-exp/',
  store: 'https://tribot.org/store/products/79-nFletcher',
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime'
    },
    exp: {
      title: 'Exp',
      type: 'number',
      defaultSort: 'desc'
    },
    profit: {
      title: 'Profit',
      type: 'number'
    }
  }
}

export const cooker = {
  name: 'nCooker',
  route: 'cooker',
  description: 'Automate cooking items for experience',
  apiRoute: 'cooker',
  signatures: true,
  forums: 'https://community.tribot.org/topic/80946-ncooker-abc2-customization-progression-supports-most-items-extremely-reliable-stable-1b-total-exp/',
  store: 'https://tribot.org/store/products/82-nCooker',
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime'
    },
    exp: {
      title: 'Exp',
      type: 'number',
      defaultSort: 'desc'
    },
    profit: {
      title: 'Profit',
      type: 'number'
    }
  }
}

export const roguesden = {
  name: 'nRogues Den',
  route: 'roguesden',
  description: 'Automate the rogues den minigame for rogue equipment',
  apiRoute: 'roguesden',
  signatures: true,
  store: 'https://tribot.org/store/products/69-nRogues-Den',
  forums: 'https://community.tribot.org/topic/81294-nrogues-den-customization-stamina-potions-numerous-settings-10000-attempts-completed/',
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime'
    },
    attempts: {
      title: 'Attempts',
      type: 'number'
    },
    crates: {
      title: 'Crates',
      type: 'number'
    },
    failures: {
      title: 'Failures',
      type: 'number'
    },
    safeReached: {
      title: 'Safe Reached',
      type: 'number',
      defaultSort: 'desc'
    }
  }
}

export const gdk = {
  name: 'nGreen Dragon Killer',
  route: 'gdk',
  description: 'Automate killing green dragons for profit',
  apiRoute: 'gdk',
  store: 'https://tribot.org/store/products/78-nGreen-Dragon%20Killer',
  forums: 'https://community.tribot.org/topic/81365-ngreen-dragon-killer-intelligent-quick-anti-pk-all-wilderness-locations-restocking-muling-customization-1-trillion-gp-looted-total/',
  signatures: true,
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime',
      defaultSort: 'desc'
    },
    loot: {
      title: 'Loot',
      type: 'number'
    },
    deaths: {
      title: 'Deaths',
      type: 'number'
    },
    exp: {
      title: 'Exp',
      type: 'number',
      composite: true,
      fields: [
        'attack',
        'strength',
        'defence',
        'hitpoints',
        'ranged',
        'magic',
      ]
    },
  }
}

export const hunter = {
  name: 'nHunter',
  route: 'hunter',
  description: 'Automate hunting popular creatures for experience and profit',
  apiRoute: 'hunter',
  store: 'https://tribot.org/store/products/76-nHunter',
  forums: 'https://community.tribot.org/topic/81624-nhunter-box-traps-net-traps-bird-snares-customization-black-chinssalamanders-abc2-muling-400b-total-profit80b-total-exp/',
  signatures: true,
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime'
    },
    profit: {
      title: 'Profit',
      type: 'number'
    },
    exp: {
      title: 'Exp',
      type: 'number',
      defaultSort: 'desc'
    },
  }
}

export const prayer = {
  name: 'nPrayer',
  route: 'prayer',
  description: 'Automate burying/offering bones for experience',
  apiRoute: 'prayer',
  forums: 'https://community.tribot.org/topic/84298-nprayer-gilded-altar-wilderness-altar-burying-abc2-unique-player-preferences-grand-exchange-1b-experience-gained/',
  store: 'https://tribot.org/store/products/71-nPrayer',
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime'
    },
    exp: {
      title: 'Exp',
      type: 'number',
      defaultSort: 'desc'
    },
  }
}

export const orbs = {
  name: 'nOrb Charger',
  route: 'orbs',
  description: 'Automate charging air orbs for experience and profit',
  apiRoute: 'orbs',
  forums: 'https://community.tribot.org/topic/81873-norb-charger-300k-500khr-air-orbs-anti-pk-customization-muling-restocking-200b-total-profit/',
  store: 'https://tribot.org/store/products/72-nOrb-Charger',
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime'
    },
    profit: {
      title: 'Profit',
      type: 'number'
    },
    exp: {
      title: 'Exp',
      type: 'number'
    },
    orbs: {
      title: 'Orbs',
      type: 'number',
      defaultSort: 'desc'
    }
  }
}

export const fighter = {
  name: 'nFighter',
  description: 'Automate fighting npcs for experience and loot',
  route: 'fighter',
  store: 'https://tribot.org/user/community-scripts?page=1&q=Naton&id=242',
  forums: 'https://community.tribot.org/topic/81271-free-nfighter-basic-aio-combat-script/',
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime',
      defaultSort: 'desc'
    },
    attack: {
      title: 'Attack',
      type: 'number'
    },
    strength: {
      title: 'Strength',
      type: 'number'
    },
    defence: {
      title: 'Defence',
      type: 'number'
    },
    hitpoints: {
      title: 'Hitpoints',
      type: 'number'
    },
    ranged: {
      title: 'Ranged',
      type: 'number'
    },
    magic: {
      title: 'Magic',
      type: 'number'
    },
    loot: {
      title: 'Loot',
      type: 'number'
    }
  }
}

export const mule = {
  name: 'nMule',
  route: 'mule',
  description: 'Automate trading items to and from your accounts',
  apiRoute: 'mule',
  store: 'https://tribot.org/user/community-scripts?page=1&q=Naton&id=168',
  forums: 'https://community.tribot.org/topic/79921-nmule-free-supports-any-nscripts/',
  isHighscoresExposed: false,
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime',
      defaultSort: 'desc'
    },
    profit: {
      title: 'Profit',
      type: 'profit'
    },
  }
}

export const dispatcher = {
  name: 'nAccount Dispatcher',
  description: 'Automate distributing accounts to your clients',
  store: 'https://tribot.org/user/community-scripts?page=1&q=Naton&id=214',
  forums: 'https://community.tribot.org/topic/83523-naccount-dispatcher/',
  isHighscoresExposed: false,
  isIncludedInStatistics: false
}

export const restocker = {
  name: 'nRestocker',
  route: 'restocker',
  description: 'Automate your gold farm',
  apiRoute: 'restocker',
  isHighscoresExposed: false,
  store: 'https://tribot.org/store/products/70-nRestocker',
  forums: 'https://community.tribot.org/topic/79895-nrestocker-grand-exchange-muling-bonding-gearing-script-startingqueuing-~100-different-tasks-automate-your-gold-farm/',
  schema: {
    username: {
      title: 'Username',
      type: 'username'
    },
    runtime: {
      title: 'Runtime',
      type: 'runtime',
      defaultSort: 'desc'
    }
  }
}

export const scriptTypes = [
  nmz,
  blastfurnace,
  crabkiller,
  mercher,
  smither,
  herblore,
  cwars,
  magic,
  blastmine,
  firemaker,
  crafter,
  fletcher,
  cooker,
  roguesden,
  gdk,
  hunter,
  prayer,
  orbs,
  fighter,
  restocker,
  mule,
  dispatcher
]

// Set some defaults
scriptTypes.forEach(type => {
  if (!type.apiRoute) {
    type.apiRoute = type.route
  }
  if (type.schema && !('isHighscoresExposed' in type)) {
    type.isHighscoresExposed = true
  }
  if (type.schema && !('isIncludedInStatistics' in type)) {
    type.isIncludedInStatistics = true
  }
})