import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import routes from './routes'
import Layout from '../layout/layout'

const Router = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          {
            routes.map((desc) => <Route key={`${desc.path}`} {...desc} />)
          }
        </Switch>
      </Layout>
    </BrowserRouter>
  )
}

export default Router