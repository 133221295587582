import React, {useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Container} from '@material-ui/core'
import {scriptTypes} from '../data/script-types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import UserSignatureSelector from '../components/user-signature-selector'

const Signatures = () => {
  const [expanded, setExpanded] = useState()
  return (
    <Container>
      {scriptTypes.filter(script => script.signatures).map(script => {
        return (
          <Accordion
            key={script.name}
            expanded={script.name === expanded}
            onChange={(e, expanded) => setExpanded(expanded ? script.name : '')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>{script.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <UserSignatureSelector script={script} />
            </AccordionDetails>
          </Accordion>
        )
      })}
    </Container>
  )
}

export default Signatures