import React from 'react'
import {Card, CardActions, CardContent} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Link from '@material-ui/core/Link'

const ScriptCard = (props) => {
  return (
    <Card style={{height: '100%'}}>
      <CardContent style={{height: 'calc(100% - 72px)'}}> {/*Trick to keep cards the same height (3 * card action height)*/}
        <Typography variant="h5" component="h2" gutterBottom>
          {props.script.name}
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          {props.script.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Link component={'a'} href={props.script.forums}>View Forums Thread (TRiBot)</Link>
      </CardActions>
      <CardActions>
        <Link component={'a'} href={props.script.store}>View Script Listing (TRiBot)</Link>
      </CardActions>
    </Card>
  )
}

ScriptCard.propTypes = {
  script: PropTypes.object.isRequired
}

export default ScriptCard