import Highscores from '../pages/highscores'
import HighscoresHome from '../pages/highscores-home'
import Home from '../pages/home'
import Signatures from '../pages/signatures'
import Statistics from '../pages/statistics'
import RefundPolicy from '../pages/refund-policy'

const routes = [
  {
    path: '/highscores',
    exact: true,
    component: HighscoresHome
  },
  {
    path: '/highscores/:script',
    exact: true,
    component: Highscores
  },
  {
    path: '/signatures',
    exact: true,
    component: Signatures
  },
  {
    path: '/stats',
    exact: true,
    component: Statistics
  },
  {
    path: '/refundpolicy',
    exact: true,
    component: RefundPolicy
  },
  {
    path: '/',
    exact: false,
    component: Home
  }
]

export default routes