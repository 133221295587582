import PropTypes from 'prop-types'
import React, {useEffect, useMemo, useState} from 'react'
import MaterialTable, {MTableBody} from 'material-table'
import {convertRuntime} from '../util/time-util'
import {makeStyles, Tooltip} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import {scriptTypes} from '../data/script-types'
import HighscoresClient from '../client/highscores-client'

const useStyles = makeStyles({
  totalCell: {
    fontWeight: 'bold'
  }
})

const StatisticsTableTotalRow = (props) => {
  const classes = useStyles()
  const totals = useMemo(() => {
    return props.data.reduce((accumulator, currentValue) => {
      accumulator.runtime += currentValue.runtime
      accumulator.exp += currentValue.exp
      accumulator.profit += currentValue.profit
      return accumulator
    }, { runtime: 0, exp: 0, profit: 0 })
  }, [props.data])
  const renderCell = (col) => {
    if (col.field === 'script') {
      return 'Overall Total'
    }
    if (props.data.length === 0) {
      return null
    }
    if (col.field === 'runtime') {
      return convertRuntime(totals[col.field])
    }
    return totals[col.field].toLocaleString()
  }
  return (
    <tr>
      {props.columns.map((col) =>
        (<td
          className={`MuiTableCell-root MuiTableCell-body ${classes.totalCell}`}
          key={col.field}
        >
          {renderCell(col)}
        </td>))}
    </tr>
  )
}

StatisticsTableTotalRow.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
}

const combine = (...values) => {
  return values.reduce((prev, current) => prev + (current ?? 0), 0)
}

const StatisticsTable = () => {

  const [data, setData] = useState([])

  useEffect(() => {
    Promise.all(scriptTypes
      .filter(s => s.isIncludedInStatistics)
      .map(type => HighscoresClient.getTotals(type.apiRoute)))
      .then(results => {
        return results.map((res, index) => {
          const result = res.data
          return {
            ...result,
            script: scriptTypes[index].name,
            exp: combine(result.exp, result.attack, result.strength, result.defence, result.defense, result.hitpoints, result.ranged, result.magic),
            profit: combine(result.loot, result.profit)
          }
        })
      })
      .then(results => setData(results))
  }, [])

  const columns = [
    { title: 'Script', field: 'script', defaultSort: 'asc' },
    { title: 'Runtime', field: 'runtime', render: rowData => convertRuntime(rowData.runtime) },
    { title: 'Exp', field: 'exp', render: rowData => rowData.exp ? rowData.exp.toLocaleString() : null },
    { title:
                <>
                    Profit
                  <Tooltip title="Note that this is not pure profit, and some scripts don't track profit but another similar metric (for example, gdk tracks loot)">
                    <InfoIcon style={{verticalAlign: 'top', marginLeft: 5}} />
                  </Tooltip>
                </>,
    field: 'profit', render: rowData => rowData.profit ? rowData.profit.toLocaleString() : null
    }
  ]
  return (
    <MaterialTable
      isLoading={data.length === 0}
      columns={columns}
      title={'nScripts'}
      options={{
        filtering: false,
        emptyRowsWhenPaging: false,
        search: false,
        paging: false
      }}
      components={{
        // eslint-disable-next-line react/display-name
        Body: bodyProps => (
          <>
            <MTableBody {...bodyProps} />
            <StatisticsTableTotalRow columns={columns} data={data} />
          </>
        ),
      }}
      data={data}
    />
  )
}

export default StatisticsTable