import React from 'react'
import {Container, Grid} from '@material-ui/core'
import {scriptTypes} from '../data/script-types'
import ScriptCard from '../components/script-card'

const Home = () => {
  return (
    <Container>
      <Grid container spacing={3}>
        {scriptTypes.map(script => {
          return (
            <Grid key={script.name} item xs={12} sm={6} md={4}>
              <ScriptCard script={script} />
            </Grid>
          )
        })}
      </Grid>
    </Container>
  )
}

export default Home