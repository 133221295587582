import React from 'react'
import {ThemeProvider} from '@material-ui/styles'
import {createMuiTheme, CssBaseline} from '@material-ui/core'
import Router from './routes/router'

const App = () => {

  const theme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#90caf9'
      }
    },
    overrides: {
      MuiAppBar: {
        colorPrimary: {
          color: 'white',
          backgroundColor: '#424242'
        }
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  )
}

export default App
