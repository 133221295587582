import React, {useEffect, useMemo, useRef, useState} from 'react'
import MaterialTable from 'material-table'
import HighscoresClient from '../client/highscores-client'
import { MTableBody } from 'material-table'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core'
import {convertRuntime} from '../util/time-util'
import {buildScriptColumns} from '../util/column-util'

const useStyles = makeStyles({
  totalCell: {
    fontWeight: 'bold'
  }
})

const HighscoresTableTotalRow = (props) => {
  const [totals, setTotals] = useState()
  const classes = useStyles()
  useEffect(() => {
    HighscoresClient.getTotals(props.scriptType.apiRoute)
      .then(res => setTotals(res.data))
  }, [])
  if (!totals) {
    return null
  }
  const renderCell = (col) => {
    if (props.scriptType.schema[col.field]?.type === 'username') {
      return 'Overall Total'
    }
    if (props.scriptType.schema[col.field]?.type === 'runtime') {
      return convertRuntime(totals[col.field])
    }
    if (col.title === '#') {
      return ''
    }
    if (col.render) {
      return col.render(totals)
    }
    return totals[col.field]?.toLocaleString()
  }
  return (
    <tr>
      {props.columns.map((col) =>
        (<td
          className={`MuiTableCell-root MuiTableCell-body ${classes.totalCell}`}
          key={col.field}
        >
          {renderCell(col)}
        </td>))}
    </tr>
  )
}

HighscoresTableTotalRow.propTypes = {
  columns: PropTypes.array.isRequired,
  scriptType: PropTypes.object.isRequired
}

const HighscoresTable = (props) => {

  const scriptType = props.scriptType
  const ref = useRef()
  const columns = useMemo(() => {
    const col = buildScriptColumns(scriptType)
    col.unshift({
      title: '#',
      field: 'tableData.id',
      sorting: false,
      render: rowData => (rowData.tableData.id + 1) + (ref.current?.state?.query?.page * ref.current?.state?.query?.pageSize),
      cellStyle: {width: 10},
      headerStyle: {width: 10},
      width: 10
    })
    return col
  }, [ scriptType ])
  return (
    <MaterialTable
      tableRef={ref}
      columns={columns}
      title={`${props.scriptType.name} Highscores`}
      localization={{
        toolbar: {
          searchPlaceholder: 'Search by Username'
        }
      }}
      options={{
        pageSize: 20,
        pageSizeOptions: [20, 50, 100],
        filtering: false,
        emptyRowsWhenPaging: false
      }}
      components={{
        // eslint-disable-next-line react/display-name
        Body: props => (
          <>
            <MTableBody {...props} />
            <HighscoresTableTotalRow key={scriptType.name} columns={columns} scriptType={scriptType} />
          </>
        ),
      }}
      data={query =>
        HighscoresClient.getHighscoresData(scriptType.apiRoute,
          query.search, query.orderBy?.field, query.orderDirection == 'asc', query.page, query.pageSize)
          .then(res => {
            return {
              data: res.data.data,
              page: query.page,
              totalCount: res.data.totalCount
            }
          })}
    />
  )
}

HighscoresTable.propTypes = {
  scriptType: PropTypes.object.isRequired
}

export default HighscoresTable