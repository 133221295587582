import {convertRuntime} from './time-util'

const buildScriptColumns = (scriptType) => {
  const columns = []
  for (const [key, value] of Object.entries(scriptType.schema)) {
    const column = {
      title: value.title,
      field: key,
    }
    if (value.defaultSort) {
      column.defaultSort = value.defaultSort
    }
    if (value.type === 'number') {
      if (value.composite) {
        column.render = rowData => value.fields
          .reduce((accumulator, current) => (rowData[current] ?? 0) + accumulator , 0).toLocaleString()
      }
      else {
        column.render = rowData => rowData[key].toLocaleString()
      }
    }
    else if (value.type === 'runtime') {
      column.render = rowData => convertRuntime(rowData.runtime)
    }
    columns.push(column)
  }
  return columns
}

export { buildScriptColumns }