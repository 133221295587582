import React, {useEffect} from 'react'
import HighscoresTable from '../components/highscores-table'
import {Container} from '@material-ui/core'
import {scriptTypes} from '../data/script-types'
import PropTypes from 'prop-types'
import {useHistory} from 'react-router-dom'

const Highscores = (props) => {
  const script = scriptTypes.find(type => props.match.params.script === type.route)
  const history = useHistory()
  useEffect(() => {
    if (!script) {
      history.replace('/')
    }
  }, [script])
  if (!script || !script.isHighscoresExposed) {
    return null
  }
  return (
    <Container>
      <HighscoresTable scriptType={script} />
    </Container>
  )
}

Highscores.propTypes = {
  match: PropTypes.object.isRequired
}

export default Highscores