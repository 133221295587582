import React from 'react'
import {Card, CardActionArea, CardContent} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import {useHistory} from 'react-router-dom'

const HighscoresScriptCard = (props) => {
  const history = useHistory()
  const columnTypes = []
  for (const [, value] of Object.entries(props.script.schema)) {
    columnTypes.push(value.title)
  }
  return (
    <Card style={{height: '100%'}}>
      <CardActionArea style={{height: '100%'}} onClick={() => history.push(`/highscores/${props.script.route}`)}>
        <CardContent style={{height: 'calc(100%)'}}>
          <Typography variant="h5" component="h2" gutterBottom>
            {props.script.name}
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            {`View ${columnTypes.join(', ')}`}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

HighscoresScriptCard.propTypes = {
  script: PropTypes.object.isRequired
}

export default HighscoresScriptCard