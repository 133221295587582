import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {LinearProgress, Typography} from '@material-ui/core'

const SignatureImage = (props) => {
  const url = `https://nscripting.com/${props.script.route}/signatures/users/${props.name}.png`
  const [imageUrl, setImageUrl] = useState()
  const [loading, setLoading] = useState()
  useEffect(() => {
    setLoading(l => l + 1)
    const prev = imageUrl
    const i = new Image()
    i.onload = () => setImageUrl(last => last === prev ? url : last) || setLoading(l => l - 1)
    i.onerror = () => setImageUrl(last => last === prev ? null : last) || setLoading(l => l - 1)
    if (props.name) {
      i.src = url
    }
    else {
      i.onerror()
    }
  }, [url])
  if (loading) {
    return <LinearProgress />
  }
  if (!imageUrl) {
    if (!props.name) {
      return null
    }
    return <Typography>No image found for &apos;{props.name}&apos;</Typography>
  }
  return (
    <a href={imageUrl}>
      <img src={imageUrl} />
    </a>
  )
}

SignatureImage.propTypes = {
  name: PropTypes.string.isRequired,
  script: PropTypes.object.isRequired
}

export default SignatureImage